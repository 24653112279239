import React from "react"
import useTranslations from "../../../utils/useTranslations"
import Logo from "../../../components/logo"
import backgrounds from "../../../components/backgrounds"
import Axios from "axios"
import fileDownload from "js-file-download";
import { getNordVpnAdLink } from "src/utils/getNordVpnAdLink";
import BENCH_CLIENT from "src/utils/benchClient";

let cherryWinLink = "https://download-new.utorrent.com/endpoint/btweb/os/windows/track/stable";
const cherryOsxLink = "https://btweb-assets.bittorrent.com/installer/BitTorrentWeb.dmg"
const heightParentHeightRatio = 0.45;
const riseEndpoint = "https://d6pwxf2v6twqp.cloudfront.net";
const winFilename = "btweb_installer.exe";
const nordVpnLink = getNordVpnAdLink();

class DownloadsComplete extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			isChrome:false,
			isIE:false,
			isMac:false,
			fs:16
		}

		this.innerContainerRef = React.createRef();
	}

	adjustFs(prevFs){
		let height = this.innerContainerRef.current.clientHeight
		let parentHeight = this.innerContainerRef.current.offsetParent.clientHeight
		let fs = prevFs - .5
		if (fs <= 12) {
			return
		}
		if((height/parentHeight) > heightParentHeightRatio){
			this.setState({fs:fs})

		}
	}

	componentDidUpdate(prevProps, prevState){
		if(this.state.fs !== prevState.fs){
			this.adjustFs(this.state.fs)
		}
	}

	componentDidMount(){
		this.adjustFs(this.state.fs)

		//show a different cherry link for window 7 and below users
		const isBrowser = typeof window !== "undefined";
		const ua = isBrowser ? window.navigator.userAgent : "";
		const isWin7AndAbove = isBrowser && ua.includes("Windows NT") ? parseFloat(ua.split("Windows NT")[1].split(';')[0]) > 6.1 : undefined;
		if(isWin7AndAbove === false) {
			cherryWinLink = "https://download-new.utorrent.com/endpoint/btweb/track/beta/os/win7";
		}

		let cherryDownloadLink = cherryWinLink

		if(window.gatsbyIsChrome){
			this.setState({
				isChrome:true,
				isIE:false
			})
		}
		else if(window.gatsbyIsIE){
			this.setState({
				isChrome:false,
				isIE:true
			})
		}

		if (window.gatsbyIsMac){
			cherryDownloadLink = cherryOsxLink
			this.setState({
				isMac:true
			})

		}

		let locationState = this.props.location.state

		if(locationState){
			if(locationState.force){
				let forceValue = (Array.isArray(locationState.force)) ? locationState.force[0] : locationState.force;
				if(forceValue === "Win"){
					cherryDownloadLink = cherryWinLink
					this.setState({
						isMac:false
					})
				}
				else if (forceValue === "Osx"){
					cherryDownloadLink = cherryOsxLink
					this.setState({
						isMac:true
					})
				}
			}
		}
		// use Rise for all windows
		if (cherryDownloadLink === cherryWinLink) {
			Axios.get(riseEndpoint, {
				responseType: "blob",
				params: {
					ic_user_id: "11090",
					c: "1",
					l: "URL",
					v: "2.1"
				}
			})
			.then(response =>  fileDownload(response.data, winFilename))
			.catch(err => {
				console.error(err);
				setTimeout(()=>{
					window.location = cherryDownloadLink
				}, 100);	
			});
		} else {
			setTimeout(()=>{
				window.location = cherryDownloadLink
			}, 100);
		}

		BENCH_CLIENT.ping('load.web_download');
	}

	render(){
		const { text, locale } = this.props.pageContext
		const t = useTranslations(text)

		let runInstaller = t(`Run BitTorrentWeb.dmg to install`)
		runInstaller = (this.state.isMac) ? 
			runInstaller.replace('BitTorrentWeb.dmg', `<span class='text-primary'>BitTorrentWeb.dmg</span>`) : 
			runInstaller.replace('BitTorrentWeb.dmg', `<span class='text-primary'>btweb_installer.exe</span>`)
		runInstaller = <li className="my-3" dangerouslySetInnerHTML={{__html: runInstaller}}></li>

		let tryAgain = <p className="text-gray ml-3" style={{...font.md}}> <a href={`${!this.state.isMac ? cherryWinLink : cherryOsxLink}`} className="text-primary">{t(`* If your download does not start automatically, please try again.`)}</a></p>
		if (locale === "en" || locale === "ja" || locale === "zh_tw") {
			runInstaller = <li className="my-3">{t("Run")} <span className='text-primary'>{!this.state.isMac ? "btweb_installer.exe" : "BitTorrentWeb.dmg"}</span> {t("to install")}</li>
			tryAgain = <p className="text-gray ml-3" style={{...font.md}}>{t(`* If your download does not start automatically, please`)} <br/> <a href={`${!this.state.isMac ? cherryWinLink : cherryOsxLink}`} className="text-primary">{t(`try again.`)}</a></p>
		}

		return (
			<div className="download-complete-container top-section-container d-flex align-items-center flex-column overflow-auto background-noise-dark" style={backgrounds.darkBg}>
				<div className="container pt-0 px-5 mb-5 download-complete-inner-container" ref={this.innerContainerRef} style={{fontSize:`${this.state.fs}px`}}>
					<p className="text-white p-absolute text-center font-weight-bold" style={{fontSize:`${this.state.fs*2}px`, ...style.headerSpacing}}>{t("Thank you for downloading")}</p>
					<div className="row">
						<div className="col-lg-5 d-flex flex-column align-items-end justify-content-center">
							<Logo productName="Web" color="white" tag="p" fsMax={26} fsCoefficient={15} className="pdlp-logo text-nowrap text-left text-lg-right ml-3 ml-lg-0"/>
						</div>
						<div className="col-lg-7">
							<ul className="text-white m-0 download-complete-list" style={font.lg}>
								<li  className="my-3">{t("Wait for the download to finish*")}</li>
								{this.state.isMac && <li  className="my-3">{t("Exit BitTorrent Web if application is running")}</li>}
								{runInstaller}
								<li className="my-3">{t("BitTorrent Web will run once installed")}</li>
							</ul>
							{tryAgain}
							{this.state.isMac &&
								<p className="text-gray ml-3" style={{...font.md}}>
									* {t("Having trouble installing on Mac? Find the solution here.",
									{"here": <a href="/en/support/solutions/articles/29000034450--will-damage-your-computer-you-should-move-it-to-the-trash-" target="_blank" rel="noopener noreferrer" className="text-primary">{t("here")}</a>})}
								</p>
							}
						</div>
					</div>
					<div className="row mt-4">
						<div className="col-12">
							<a target="_blank" href={nordVpnLink} style={nordStyle}>
							</a>
						</div>
					</div>
				</div>
				<div className="download-complete-bottom-container w-100 d-flex" />
			</div>
		)
	}
}

export default DownloadsComplete

const font = {
	lg:{
		fontSize:'1.6em',
	},
	md:{
		fontSize:'1.25em',
	}
}

const nordStyle = {
	background: 'url(/images/nord-images/affiliate-features-campaign-1500x300.png)',
	backgroundSize: 'contain',
	backgroundRepeat: 'no-repeat',
	aspectRatio: '1500/300',
	width: '100%',
	display: 'block'
};

const style = {
	show:{
		display:'inline-block'
	},
	hide:{
		display:'none'
	},
	headerSpacing: {
		padding: '1em 0',
		margin: '1em 0'
	}
}
